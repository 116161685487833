import { Link , NavLink } from "react-router-dom";
import "./NavbarStyles.css";

import React, {useState} from "react" ;
import {FaBars, FaTimes} from "react-icons/fa" ;

/* import logo from "../assests/AlApex_logo.png" ;  */
import logo from "../assests/AlapexLogoTransparent.png" ; 

const Navbar = () => {


const [click, setClick] = useState(false);
const handleClick = () => setClick(!click) ;

const [color, setColor] = useState(false) ; 
const changeColor = () => {
  if(window.scrollY >= 100) {
    setColor(true);
  } else {
    setColor(false) ;
  }
}

window.addEventListener("scroll" , changeColor) ; 

  return (
    <div className={color ? "header header-bg" : "header" }>
        
        <Link to="/" className="logo">
          <img src={logo} alt="AlApex Engerprise " width="125" height="75"/>
        </Link>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
               <li>  <NavLink to="/">Home</NavLink> </li>  
              <li>  <NavLink to="/services">Services</NavLink> </li>
              <li>  <NavLink to="/about">About</NavLink> </li> 
              <li>  <NavLink to="/contact">Contact</NavLink>  </li>
            </ul>

            <div className="hamburger" onClick={handleClick}>
            {click ? 
            (<FaTimes size={20} style={{color:"#191970"}}/>) 
            :
            ( <FaBars size={20} style={{color:"#191970"}}/> )
           }
        
            </div>
    </div>
  )
}

export default Navbar