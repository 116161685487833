import React from 'react'
import "./AboutUsStyles.css" ;

import headerimage from "../assests/aboutus1.jpg" ;  


export const AboutUs = () => {
  return (
    <div className="hero">                                                
        <div className="mask">
        <img className="intro-img" src={headerimage} alt="AboutUs"  />
        </div>
  </div>


  )
}

export default AboutUs