
import React from 'react' ;
/* import {Link} from "react-router-dom" ;*/

/* import { HashLink as Link } from "react-router-hash-link"; */


import IntroImg from "../assests/cloud.jpg" ; 
import service_img1 from "../assests/DataMagement4.jpg" ;
import service_img2 from "../assests/DataWarehose3.jpg" ;
import service_img3 from "../assests/DL1.jpg";
import service_img4 from "../assests/DataAnalytics3.jpg"
import service_img5 from "../assests/Migration1.jpg"

import "./HeroImgStyles.css" ;
import "./ServicesHeaderStyles.css" ;

const ServicesHeader = () => {
    return  <div className="hero">                                                
    <div className="mask">
        <img className="intro-img" src={IntroImg} alt="Services"  />
    </div>
    <div id="dm_anchor"  className="content">
{/*         <h2>Our Services</h2> */}
<br/>   <br/>   <br/>   

        <h4>We analyse unique business needs and expectations and provide distinctive IT solutions to business users for better ROI
        </h4>
{/*     <Link to="#dm_anchor" className="btn">Know More</Link> */}
    </div>


   <section id="dw_anchor" className="DataManagement">
    <div className="container serice_container"> 
    <div className="service_image"> 
        <img  src={service_img1} alt="DataManagement Services"  />
    </div>

    <div className="service_content">
    <h1>Data Management</h1>
            <p>At our company, we provide a range of data management services to help organizations make the most of their data. Our services are designed to help organizations store, manage, analyze, and leverage data effectively, with a focus on improving data quality, consistency, and accessibility.</p>

            <p>At our company, we believe that effective data management is essential for any organization that wants to succeed in today's data-driven world. With our expertise and experience, we can help you make the most of your data, improving your decision-making processes and driving business growth. Contact us today to learn more about our data management services and how we can help your organization thrive. </p>
  
    </div>
  
    </div>
    </section>


    <section id="dla_anchor"  className="DataWarehouse">
    <div className="container serice_container1"> 
    <div className="service_content1">
    <h1>Data Warehouse</h1>
            <p>Our company is a specialized provider of data warehouse services, offering a wide range of solutions to help organizations manage and analyze their data. With our expertise and experience, we can help you design, build, and maintain a data warehouse that meets your specific needs, from data architecture and modeling to ETL processes and performance tuning.</p>

            <p>Our team of experts is committed to delivering high-quality data warehouse services that meet the unique needs of each of our clients. We work closely with our clients to understand their business requirements and provide customized solutions that enable them to achieve their goals. Our services are designed to be scalable, flexible, and cost-effective, ensuring that our clients can grow and adapt their data warehouse infrastructure as their business needs evolve.</p>

            <p>At our company, we believe that data is the lifeblood of any organization. With our specialized data warehouse services, we can help you make the most of your data, unlocking valuable insights that can drive business growth and success. Contact us today to learn more about our data warehouse services and how we can help your organization thrive.</p>
  
    </div>
    <div className="service_image1"> 
        <img className="dw_service_img" src={service_img2} alt="Data Warehouse Services"  />
    </div>
    </div>
    </section>

    <section id="da_anchor" className="DataLake">
    <div className="container serice_container"> 
    <div className="service_image"> 
        <img className="dla_service_img" src={service_img3} alt="Data Lake and Visualization Services"  />
    </div>

    <div className="service_content">
    <h1>Data Lake and Visualization</h1>
            <p>Our company offers expert Data Lake and Visualization services, helping organizations to leverage their data and derive valuable insights for better decision-making. Our team of experts can help you design, implement and maintain a Data Lake that meets your specific needs, from data ingestion and storage to advanced analytics and visualization.</p>

            <p>With our expertise and experience, we can help you make sense of your data and turn it into actionable insights. We use advanced visualization tools and techniques to help you better understand your data and identify trends, patterns, and opportunities. Whether you need to analyze customer behavior, optimize business processes, or improve operational efficiency, we can provide customized solutions that enable you to achieve your goals.</p>

            <p>At our company, we are committed to delivering high-quality Data Lake and Visualization services that provide real value to our clients. Whether you are just getting started with your data journey or need help taking your existing data infrastructure to the next level, our team is here to help. Contact us today to learn more about our Data Lake and Visualization services and how we can help you unlock the full potential of your data.</p>
  
    </div>
  
    </div>
    </section>


    <section  className="DataAnalytics">
    <div  className="container serice_container1"> 
 

    <div id="dbmigration_anchor" className="service_content1">
    <h1>Data Analytics and Business Intelligence</h1>

            <p>Data analytics and business intelligence services are essential for businesses to make informed decisions and gain a competitive advantage in the market. Our company provides expert Data Analytics and Business Intelligence services, helping organizations to unlock the full potential of their data. We offer a wide range of services, including data warehousing, data integration, data mining, and data visualization. Our team of experts can help you turn your data into actionable insights that drive business growth and success.</p>

            <p>With our expertise and experience, we can help you make sense of your data, identify key trends and patterns, and make informed decisions. We use advanced analytics and visualization tools to help you better understand your data and gain deeper insights into your business. Whether you need to improve customer engagement, optimize supply chain operations, or increase profitability, we can provide customized solutions that meet your specific needs.</p>

            <p>With our Data Analytics and Business Intelligence services, we can help you make the most of your data, delivering insights that drive growth and improve operational efficiency. Contact us today to learn more about our services and how we can help you achieve your business objectives.</p>
    </div>
    <div className="service_image1"> 
        <img className="bi_service_img" src={service_img4} alt="Data Analytics and BI"  />
    </div>
    </div>
    </section>

    <section  className="DatabaseMigration">
    <div className="container serice_container"> 
    <div className="service_image"> 
        <img className="db_service_img" src={service_img5} alt="Database Migration"  />
    </div>

    <div className="service_content">
    <h1>Data base migration to Cloud</h1>
            <p>Our company provides expert Database Migration to Cloud services, helping organizations to migrate their databases to the cloud seamlessly. We understand that database migration can be a challenging and complex process, which is why we offer end-to-end services to ensure a smooth and hassle-free migration. Our team of experts can help you assess your current database infrastructure, plan the migration process, and implement a cloud-based solution that meets your specific requirements. </p>

            <p>With our expertise and experience, we can help you achieve your goals for cloud-based data management, such as scalability, reliability, and cost-efficiency. Our team of experts can assist you in choosing the right cloud platform, evaluating the migration options, and ensuring a successful transition to the cloud. Whether you need to migrate a single database or a complex environment, we can provide customized solutions that meet your specific needs.</p>

            <p>At our company, we believe that cloud-based data management is the future of modern business. With our Database Migration to Cloud services, we can help you leverage the power of the cloud for improved data management and business success. Contact us today to learn more about our services and how we can help you achieve your goals for cloud-based data management.</p> 
    </div>
  
    </div>
    </section>

  </div>
}
export default ServicesHeader