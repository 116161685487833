import "./FooterStyles.css" ;

import React from 'react' ;

import {FaHome, FaMailBulk, FaPhone} from "react-icons/fa" ;

const Footer = () => {
  return (
    <div className="footer">
        <div className="foter-container">
            <div className="left">
                <div className="location">
                    <FaHome size={20} style={{color:"#fff", marginRight:"2rem"}}/>
                    <div>
                    <p>AlApex Enterprises</p>
                    {/* <p>Pune, Maharashtra, India</p> */}
                    <p>Office No.2, First Floor, Dharmawat Corner, Kondhwa Budruk, Pune, Maharashtra, India</p>
                    </div>                
                </div>

                <div className="phone">
                   
                    <FaPhone size={20} style={{color:"#fff", marginRight:"2rem"}}/>
                    +91-7020225270
                 
                </div>
                <div className="e-mail">
                    <FaMailBulk size={20} style={{color:"#fff", marginRight:"2rem"}}/>
                    info@AlApex.com
                   
                </div>
            </div>
                


            <div className="right">
                <h5>About Company</h5>
                <p>Al-Apex is fast growing startup in Information Technology industry with rich experience in application development, Data ware house and Business Intelligence</p>
            </div>
        </div>
<div className="footer_copyright">
    <small>Copyright &copy;  All Rights Reserved by 2022 Al-Apex Enterprises</small>
</div>
    </div>
  )
}

export default Footer