import React from 'react' ;

import Navbar from "../components/Navbar" ;


/* import HeroImg2 from "../components/HeroImg2"; */
import AboutUs from "../components/AboutUs" ; 
import Footer from "../components/Footer" ; 
import AboutUsSections from "../components/AboutUsSections" ;  



const About = () => {
  return (
    <div>
    <Navbar/>
    <AboutUs/>
    <AboutUsSections/> 
   <Footer/>  
  </div>
  )
}

export default About