
import pro1 from "../assests/DataMagement3.jpg"
import pro2 from "../assests/DataWarehose5.jpg"
import pro3 from "../assests/DL5.jpg"
import pro4 from "../assests/DataAnalytics.jpg"

import pro5 from "../assests/Migration4.jpg"

const ProjectCardData = [
    {
        id:1,
        imgsrc: pro1,
        title: "Data Management",
        text: "We offer data management services to help organizations organize, maintain, and leverage their data effectively. Our services aim to improve data accuracy, consistency, and accessibility for better decision-making and customer experience",
        view: "/services#dm_anchor" ,
    

    },
    {
        id:2,
        imgsrc: pro2,
        title: "Data Warehouse Maintenance",
        text: "Our company provides Data Warehouse Maintenance and Services to ensure that your data warehouse is running efficiently and effectively. We offer ongoing maintenance and support, as well as consulting services to help optimize your data warehouse for improved performance and scalability." ,
        view:"/services#dw_anchor",

    },  
    {
        id:3,
        imgsrc: pro3,
        title: "Data Lake and Visualization",
        text: "We specialize in Data Lake implementation and Visualization services, helping organizations to set up and manage their data lakes and derive valuable insights from their data. Our team of experts can guide you through the entire process, from data ingestion and storage to advanced analytics and visualization." ,
        view:"/services#dla_anchor",
    } ,  
    {
        id:4,
        imgsrc: pro4,
        title: "Data Analytics and Business Intelligence",
        text: "Our Data Analytics and Business Intelligence services help organizations to turn their data into actionable insights for better decision-making. We offer customized solutions tailored to each client's unique needs, leveraging advanced analytics and visualization techniques to deliver meaningful and impactful results." ,
        view:"/services#da_anchor",
    } ,  
    {
        id:5,
        imgsrc: pro5,
        title: "Data base migration to Cloud",
        text: "We provide expert services for migrating databases to the cloud, ensuring a smooth and seamless transition for your organization. Our team can help you assess your current database infrastructure, plan the migration process, and implement a cloud-based solution that meets your specific requirements." ,
        view:"/services#dbmigration_anchor",
    }  

];

export default ProjectCardData;
