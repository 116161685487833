import "./WorkCardStyles.css" ;

import React from 'react';

/* import {NavLink} from "react-router-dom" ; */
import { HashLink as Link } from "react-router-hash-link";



const WorkCard = (props) => {
  return (
    <div className="project-card">

    <img className="project_img" src={props.imgsrc} alt="service" />
    
      <h4 className="project-title">{props.title}</h4>
      <div className="project-details">
        <p>{props.text}</p>
        <div className="pros-btn">
          <Link to={props.view} className="Read_More">
          Read More
          </Link>
        </div> 
      </div>
  </div>
  )
}

export default WorkCard