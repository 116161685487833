import "./ContactUsStyles.css" ;


// import contactus from "../assests/contactus2.jpg" ; 
/* import {Link} from "react-router-dom" ; */

import { FaMailBulk} from "react-icons/fa" ;
import React from 'react' ;

function ContactUs() {
    return  <div className="hero">  

    <div className="mask">
     {/* <img className="intro-img" src={contactus} alt="Contact Us"  />  */}
    </div>

    <div className="content">
        <h2>Get in Touch</h2>
        <h4>        We would be happy to connect, write us your queries        </h4>

    <section className="contact">
        <div className="container contact_container">
            <div className="contact_wrapper">
                <a href="mailto:info@alapex.com" target="_blank" rel="noreferrer noopener"><FaMailBulk/></a>
            </div>

        </div>
    </section> 
{/*     <Link to="/services" className="btn">Send Mail </Link> */}

    </div>
    
  </div>
}

export default ContactUs