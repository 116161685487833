import React from 'react' ;
import "./AboutUsSectionsStyles.css" ;

const AboutUsSections = () => {
  return (
    <div className="aboutsection_container">
        {/* <h4>AboutUsSections</h4> */}
    

    <section id="aboutus" className="aboutus">
        <div className="container about_container"> 
        <div className="joinlegands_content">
                        <h1>Join the Legends</h1>
                        <p>
                        Al-Apex is fast growing startup in Information Technology industry with rich experience in application development, Data ware house and Business Intelligence
                        </p>
                        <p>
                        We are team of experts from various domains with proven 20+ industry experience. Our Team has been built on a strong foundation of intellectual capital and commitment to the best results.
                        </p>
        </div>


            <div className="vision_content">
                <h1>VISION</h1>
                    <p>To provide highly skilled IT professionals to effectively manage technology projects, project-based IT-Consultants and full-time technology professionals, temporary IT support staff
                    </p>

                    <p>To bridge the gap between technology and the end user by delivering efficient and cost effective solutions.
                    </p>

                    <p>To Guide young Entrepreneurs and Engineers on Application Development, IT solutions and different phases of Project Life Cycle.
                    </p>

            </div>

            <div className="mision_content">
                        <h1>MISSION</h1>     
                            <p>To serve, add value & create growth for individuals, businesses & entities, while being an ethical, self-sustained & an active contributor to global development.
                            </p>

                            <p>Create job opportunities
                            </p>

                            <p>Promote IT awareness for growth of individuals, businesses and society as a whole.
                            </p> 
                            
                            <p>
                            Undertake social welfare projects, as a responsible corporate organization.  
                            </p>
            </div>           






        </div>
    </section>

     </div>

  )
}

export default AboutUsSections