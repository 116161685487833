import "./HeroImgStyles.css" ;

import React from 'react' ;

import IntroImg from "../assests/bg2.jpg" ; 
/* import {Link} from "react-router-dom" ; */

const HeroImg = () => {
  return  <div className="hero1">                                                
    <div className="mask1">
        <img className="intro-img1" src={IntroImg} alt="Data Lake"  />
    </div>
    <div className="content">
        <h2>
        Melodic lines build up to the chorus and it Apexes at the Solo
        </h2>
        <h4>
        Bridging gap between IT solutions and Business Users
        </h4>

{/*     <Link to="/services" className="btn">Services</Link>
    <Link to="/contact" className="btn btn-light">Contact</Link> */}
    </div>
    
  </div>
}

export default HeroImg