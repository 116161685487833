
import React from "react" ;
import "./index.css";
import Home from "./routes/Home"
import Services from "./routes/Services"
import About from "./routes/About"
import Contact from "./routes/Contact"
import {Helmet} from "react-helmet";

import { Route, Routes} from "react-router-dom";

function App() {
  return (
    <div className="App">
            <Helmet>
                <meta charSet="utf-8" />
                <title>The Apex Enterprise</title>
                <link rel="canonical" href="http://mysite.com/example" />
                <meta name="description" content="Data Analytical Solutions" />
            </Helmet>
      <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      </>

    </div>
  );
}

export default App;
