import React from "react" ;

import Navbar from "../components/Navbar" ;

import Footer from "../components/Footer" ; 
/* import Work from "../components/Work"; */
import "../components/WorkCardStyles.css";
import ServicesHeader from "../components/ServicesHeader";



const Services = () => {
  return (
    <div>
    <Navbar/> 
    <ServicesHeader />
     <Footer/> 
    </div>
  )
}

export default Services